/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react'
import { usePagination } from 'hooks'
import { Table, Icon, Button, Input, Spinner, Image } from 'components/common'
import { useTranslation } from 'react-i18next'
import noImage from 'assets/images/empty.png'

const PER_PAGE_PAGINATION = 5

export default function CartListing({ data, onDelete, onUpdateQuantity }) {
  const { t, i18n } = useTranslation()
  const [displayedItems, setDisplayedItems] = useState([])

  const convertUnicode = (input) => {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) => String.fromCharCode(parseInt(b, 16)))
  }

  const handleUpdateQuantity = (id, updatedQuantity, item) => {
    onUpdateQuantity(id, updatedQuantity, item)
  }

  const getButtonStyles = (backgroundColor, opacityValue = 0.7) => ({
    backgroundColor,
    borderRadius: 0,
    opacity: opacityValue
  })

  const columns = useMemo(
    () => [
      {
        Header: t('Part Number'),
        accessor: 'part_number',
        width: 50
      },
      {
        Header: t('Image'),
        Cell: ({ row }) =>
          row.original?.images[0]?.url ? (
            <img src={row.original?.images[0]?.url} width={50} height={50} alt="product" />
          ) : (
            <img src={noImage} width={50} height={50} alt="no image" />
          ),
        width: 50
      },
      {
        Header: t('Lookup'),
        accessor: 'lookup',
        width: 50
      },
      {
        Header: t('Description'),
        Cell: ({ row }) => {
          if (row.original.description) {
            const descriptionTranslations = row.original.description ?? '';
            return <span>{descriptionTranslations}</span>
          }
          return null
        },
        width: 60
      },
      {
        Header: t('Location'),
        accessor: 'location',
        width: 50
      },
      {
        Header: t('Unit Price'),
        Cell: ({ row }) => (
          <>
            {row.original.combined_price ? (
              <span>{row.original.combined_price.toFixed(2)} £</span>
            ) : (
              <Spinner />
            )}
          </>
          // <span>{`${row.original.price.price.toFixed(2)} ${convertUnicode(
          //   row.original.price.symbol
          // )}`}</span>
        ),
        width: 40
      },
      {
        Header: t('Total Price'),
        Cell: ({ row }) => (
          <>
            {row.original.combined_price ? (
              <span>{(row.original.combined_price * row.original.quantity).toFixed(2)} £</span>
            ) : (
              <Spinner />
            )}
          </>
          // <span>{`${(row.original.price.price * row.original.quantity).toFixed(2)} ${convertUnicode(
          //   row.original.price.symbol
          // )}`}</span>
        ),
        width: 40
      },

      {
        Header: t('Stock'),
        Cell: ({ row }) => (
          <>
            {row.original.combined_stock >= 0 ? (
              <span>{row.original.combined_stock}</span>
            ) : (
              <Spinner />
            )}
          </>
        ),

        width: 35
      },
      {
        Header: t('Quantity'),
        Cell: ({ row }) => (
          <div className="flex flex-align-center">
            <Button
              //   style={getButtonStyles('#263355', row.original.combined_stock ? 1 : 0.7)}
              style={getButtonStyles('#263355', 1)}
              //   disabled={!row.original.combined_stock}
              onClick={() =>
                handleUpdateQuantity(row.original.id, row.original.quantity - 1, row.original)
              }
            >
              -
            </Button>
            <Input
              disabled
              type="number"
              style={{ borderRadius: 0, width: 50, textAlign: 'center' }}
              value={row.original.quantity}
            />
            <Button
              //   style={getButtonStyles('#263355', row.original.combined_stock ? 1 : 0.7)}
              style={getButtonStyles('#263355', 1)}
              //   disabled={!row.original.combined_stock}
              onClick={() =>
                handleUpdateQuantity(row.original.id, row.original.quantity + 1, row.original)
              }
            >
              +
            </Button>
          </div>
        ),
        width: 50
      },
      {
        Header: t('Action'),
        Cell: ({ row }) => (
          <div
            className="cursor-pointer flex flex-justify-start ml-sm flex-align-center w-full"
            onClick={() => onDelete(row.original.id)}
          >
            <Icon iconClass="trash-delete" type="danger" size="16px" />
          </div>
        ),
        width: 30
      }
    ],
    [displayedItems]
  )

  const { activePage, pagesCount, setActivePage } = usePagination({
    totalCount: data.length,
    perPage: PER_PAGE_PAGINATION
  })

  const paginateProducts = ({ cartsList }) => {
    const startIndex = activePage * PER_PAGE_PAGINATION
    const endIndex = startIndex + PER_PAGE_PAGINATION
    return cartsList.slice(startIndex, endIndex)
  }

  useEffect(() => {
    const nextProductsList = paginateProducts({ cartsList: data })
    setDisplayedItems(nextProductsList)
  }, [data, activePage])

  const showTable = useMemo(() => {
    return (
      <Table
        data={displayedItems}
        columns={columns}
        hasPagination
        pagesCount={pagesCount}
        setActivePage={setActivePage}
      />
    )
  }, [displayedItems])

  return <div className="cart-table-wrapper">{showTable}</div>
}
