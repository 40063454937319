import * as actionTypes from './actions'
import _ from 'lodash'

const initialState = {
  initCatalog: { basket: [], makes: [] },
  loading: false,
  error: null
}

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INIT_CATALOG_REQUEST:
    case actionTypes.ADD_TO_CART_PROMISE:
    case actionTypes.CALL_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_INIT_CATALOG_SUCCESS:
      return {
        ...state,
        initCatalog: action.payload,
        loading: false,
        error: null
      }

    case actionTypes.CALL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }

    case actionTypes.ADD_TO_CART_PROMISE_SUCCESS: {
      const { branch_id, product_price_id, quantity, hub_id, part_number_id } = action.payload.requestData
      const basket = _.cloneDeep(state.initCatalog.basket) || []
      const cartItem = basket?.find(
        (item) => {
          if (item.branch_id === branch_id) {
              return item.hub_id ? item.part_number_id == part_number_id && item.hub_id == hub_id : item.product_price_id === product_price_id;
          }
          return false;
        }
      )
      if (cartItem) {
        cartItem.quantity += quantity
      } else {
        basket.push(action.payload.requestData)
      }

      return {
        ...state,
        initCatalog: {
          ...state.initCatalog,
          basket: basket
        },
        loading: false,
        error: null
      }
    }

    case actionTypes.REMOVE_FROM_BASKET_BY_IDS:
      const removedItemIds = action.payload
      const updatedBasketAfterRemoval = state.initCatalog.basket.filter(
        (cart) => !removedItemIds.includes(cart.id)
      )

      const updatedInitCatalogue = { ...state.initCatalog, basket: updatedBasketAfterRemoval }

      return { ...state, initCatalog: updatedInitCatalogue }

    case actionTypes.REMOVE_FROM_BASKET:
      const itemIdToRemove = action.payload.id
      const updatedBasket = state.initCatalog.basket.filter((item) => item.id !== itemIdToRemove)

      const updatedInitCatalog = { ...state.initCatalog, basket: updatedBasket }

      return { ...state, initCatalog: updatedInitCatalog }

    case actionTypes.FETCH_CARTS_SUCCESS: {
        const {data} = action.payload;
        const basket = _.map(state.initCatalog.basket, (item) => {
          const matchedItem = _.find(data, (payloadItem) => {      
            return payloadItem.hub_id
              ? payloadItem.part_number_id === item.part_number_id && payloadItem.hub_id === item.hub_id
              : payloadItem.product_price_id === item.product_price_id;
          });
      
          return {
            ...item,
            id: matchedItem?.id || item.id,
          };
        });
      
        return {
          ...state,
          initCatalog: {
            ...state.initCatalog,
            basket,
          },
          loading: false,
          error: null,
        };
      }

    case actionTypes.CLEAR_BASKET:
      const clearedInitCatalog = { ...state.initCatalog, basket: [] }

      return { ...state, initCatalog: clearedInitCatalog }

    case actionTypes.FETCH_INIT_CATALOG_FAILURE:
    case actionTypes.ADD_TO_CART_PROMISE_FAILURE:
    case actionTypes.CALL_REQUEST_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }

    default:
      return state
  }
}

export default catalogReducer
