import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const activeClassName = 'navigation__link--active'

const NavigationItem = ({ path, label, icon, hasBadge, badgeCount, items, disabled }) => {
  const [open, setOpen] = useState(false)

  const toggleState = useCallback(() => {
    setOpen((prevState) => !prevState)
  }, [])

  const body = (
    <>
      <div className="navigation__icon">{icon}</div>
      {label}
      {hasBadge && <span className="badge badge--danger ml-md">{badgeCount}</span>}
    </>
  )

  const disabledStyles = {
    color: '#888', // Change to the desired color for the disabled state
    cursor: 'not-allowed',
    pointerEvents: 'none',
    backgroundColor: '#f0f0f0', // Add a background color for the disabled state
  }

  return (
    <li className="navigation__item">
      {items ? (
        <>
          <button
            onClick={toggleState}
            className={`navigation__link ${open ? 'navigation__link--active' : ''}`}
            type="button"
          >
            {body}
            <span className="navigation__arrow" />
          </button>
          {open && (
            <div className="navigation__item inner">
              {items && items.length > 0 && (
                <ul>
                  {items.map((item) => (
                    <NavigationItem key={item.label} {...item} />
                  ))}
                </ul>
              )}
            </div>
          )}
        </>
      ) : disabled ? (
        <span
          className="navigation__link navigation__link__disabled"
          style={disabledStyles} // Apply inline styles when disabled
        >
          {body}
        </span>
      ) : (
        <NavLink
          to={path}
          className={(navData) =>
            navData.isActive ? `navigation__link ${activeClassName}` : 'navigation__link'
          }
        >
          {body}
        </NavLink>
      )}
    </li>
  )
}

NavigationItem.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  hasBadge: PropTypes.bool,
  badgeCount: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool, // New PropType
}

NavigationItem.defaultProps = {
  path: undefined,
  label: undefined,
  icon: undefined,
  hasBadge: false,
  badgeCount: 0,
  items: undefined,
  disabled: false, // Default value
}

export default NavigationItem
